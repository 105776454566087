"use client";

import React, { useState, useEffect, useRef } from "react";

const SnakeGame = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [snake, setSnake] = useState([
        { x: 10, y: 10 },
        { x: 9, y: 10 },
        { x: 8, y: 10 },
    ]);
    const [food, setFood] = useState({ x: 15, y: 15 });
    const [direction, setDirection] = useState("RIGHT");
    const [gameOver, setGameOver] = useState(false);

    const gridSize = 20;

    // Specify the type of the event
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" && direction !== "DOWN") setDirection("UP");
        if (e.key === "ArrowDown" && direction !== "UP") setDirection("DOWN");
        if (e.key === "ArrowLeft" && direction !== "RIGHT") setDirection("LEFT");
        if (e.key === "ArrowRight" && direction !== "LEFT") setDirection("RIGHT");
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [direction]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!gameOver) {
                moveSnake();
            }
        }, 100);

        return () => clearInterval(interval);
    }, [snake, direction]);

    const moveSnake = () => {
        const newSnake = [...snake];
        const head = { ...newSnake[0] };

        if (direction === "UP") head.y -= 1;
        if (direction === "DOWN") head.y += 1;
        if (direction === "LEFT") head.x -= 1;
        if (direction === "RIGHT") head.x += 1;

        newSnake.unshift(head);

        if (head.x === food.x && head.y === food.y) {
            setFood({
                x: Math.floor(Math.random() * gridSize),
                y: Math.floor(Math.random() * gridSize),
            });
        } else {
            newSnake.pop();
        }

        if (checkCollision(head, newSnake)) {
            setGameOver(true);
        } else {
            setSnake(newSnake);
        }
    };

    const checkCollision = (head: { x: number; y: number }, snakeBody: { x: number; y: number }[]) => {
        if (
            head.x < 0 ||
            head.x >= gridSize ||
            head.y < 0 ||
            head.y >= gridSize
        ) {
            return true;
        }

        for (let i = 1; i < snakeBody.length; i++) {
            if (snakeBody[i].x === head.x && snakeBody[i].y === head.y) {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw snake
        snake.forEach((segment) => {
            ctx.fillStyle = "#00FF00";
            ctx.fillRect(segment.x * gridSize, segment.y * gridSize, gridSize, gridSize);
        });

        // Draw food
        ctx.fillStyle = "#FF0000";
        ctx.fillRect(food.x * gridSize, food.y * gridSize, gridSize, gridSize);
    }, [snake, food]);

    return (
        <div className="relative flex h-[100vh] flex-col items-center justify-center gap-5 bg-[#0E1016] text-[#e4ded7]">
            <h1 className="absolute top-10 text-[50px] font-bold">404</h1>
            {gameOver ? (
                <h1>Game Over</h1>
            ) : (
                <canvas
                    ref={canvasRef}
                    width={gridSize * gridSize}
                    height={gridSize * gridSize}
                    className="bg-[#333]"
                />
            )}
        </div>
    );
};

export default SnakeGame;
